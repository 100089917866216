<template lang="pug">
  div
    v-container(fluid fill-height)
      v-row
        v-col(
          v-for="(kpi, i) in kpis"
          :key="`${i}-a`"
        )
          v-card.fill-height(
            :loading="(kpi.type === 'weather' && loadingWeather)"
          )
            v-list-item
              v-list-item-avatar(color="grey lighten-4")
                v-icon(color="primary") {{ kpi.icon }}
              v-list-item-content
                v-list-item-title {{ kpi.name }}
            apexchart(
              v-if="kpi.type === 'pie'"
              :options="kpi.opts"
              :series="[kpi.calc, kpi.calcOf - kpi.calc]"
              type="donut"
              height="200"
            )
            apexchart(
              v-if="kpi.type === 'pie-multiple'"
              :options="kpi.opts"
              :series="kpi.series"
              type="donut"
              height="200"
            )
            v-card.d-flex.align-center.justify-center(
              v-else-if="kpi.type === 'clock' && currentTime"
              height="200"
              flat
              color="transparent"
            )
              div.text-h2.text-center.headline
                | {{ currentTime | momentFormat('HH:mm') }}
            v-card.d-flex.align-center.justify-center(
              v-else-if="kpi.type === 'sum'"
              height="200"
              flat
              color="transparent"
            )
              div.text-h2.text-center.headline {{ kpi.calc }}
                span.grey--text(v-if="kpi.sensorType") {{ kpi.sensorType.unit }}
            v-card.d-flex.justify-center.align-center.flex-wrap(
              v-else-if="kpi.type === 'asset-types'"
              flat
              height="200"
            )
              v-chip.ma-1(
                v-for="item in kpi.items"
                :key="item.id"
                color="primary"
              )
                v-avatar(color="white" left)
                  v-icon(color="primary" small) {{ item.icon }}
                v-avatar(right)
                  strong {{ item.count }}
            v-card-text(
              v-else-if="kpi.type === 'weather'"
              max-height="200"
            )
              v-row(align="center")
                v-col(cols="8")
                  span.text-h3 {{ actualWeather.temperature }}&deg;C
                v-col(cols="4")
                  v-img(
                    v-if="actualWeatherIcon"
                    contain
                    :src="actualWeatherIcon"
                    max-height="80"
                  )
              v-row
                v-col(cols="12")
                  v-chip-group(column)
                    v-chip(color="transparent")
                      v-icon(left color="primary") mdi-solar-power
                      | {{ actualWeather.sunpower }}
                    v-chip(color="transparent")
                      v-icon(left color="primary") mdi-update
                      | {{ $moment(actualWeather.timestamp) | momentFromNow }}
      v-row
        v-col(v-if="filteredAssetsWithLatLng.length > 0" cols="12" md="4")
          v-card(
            :loading="isLoadingAssets"
          )
            AssetsMap(height="61vh" :assets="getAssets" :zoom="8")
        v-col(cols="12" :md="filteredAssetsWithLatLng.length > 0 ? 8 : 12")
          v-card(flat color="transparent")
            v-row(v-if="hasAssetProjects")
              v-col(cols="12")
                AssetProjects(view-only)
            v-row
              v-col(cols="12")
                v-card
                  AssetsTable(
                    hide-default-header
                    :assets="getAssets"
                    :actions="false"
                    details
                    :interval="10000"
                    :sort-by="['activeAlarms']"
                    :sort-desc="[true]"
                  )
    v-footer(
      dark
      color="primary"
    )
      v-card.flex.py-2.px-4(
        flat
        tile
        color="transparent"
        height="200"
      )
        v-row(justify="space-between" align="center")
          v-col(cols="3")
            v-list-item
              v-list-item-content
                v-list-item-title.headline
                  | Asset Monitoring &amp; Control
                v-list-item-subtitle
                  span(v-if="currentOrganization")
                    | {{ currentOrganization.name }}
                  span(v-else)
                    | HOB IT Services B.V.
          v-col(cols="2")
            ProductByHOB
          v-col.text-h2.text-right.headline(cols="7" v-if="currentTime")
            v-icon.mr-4(x-large) mdi-clock-outline
            | {{ currentTime | momentFormat('HH:mm') }}
</template>

<script>
import Vue from 'vue';
import { mapGetters } from 'vuex';
import axios from 'axios';
import colors from 'vuetify/lib/util/colors';

import AssetsTable from '@/components/assets/AssetsTable.vue';
import AssetCard from '@/components/assets/AssetCard.vue';
import AssetsMap from '@/components/assets/AssetsMap.vue';
import ProductByHOB from '@/components/ProductByHOB.vue';
import AssetProjects from '@/components/assets/AssetProjects.vue';
import consts from '@/helpers/consts';

export default Vue.extend({
  name: 'StatusOverview',

  props: {
    organizationIds: {
      type: Array,
      default: () => [],
    },
  },

  data() {
    return {
      loadingWeather: true,
      weatherData: null,
      intervalData: false,
      intervalTime: false,
      currentTime: null,
    };
  },
  components: {
    AssetCard,
    AssetsTable,
    AssetsMap,
    ProductByHOB,
    AssetProjects,
  },
  mounted() {
    this.loadWeatherData();
    this.currentTime = this.$moment();
    this.intervalData = setInterval(() => {
      this.loadWeatherData();
      this.$store.dispatch('GET_ASSETS');
    }, 15 * 60 * 1000);
    this.intervalTime = setInterval(() => {
      this.currentTime = this.$moment();
    }, 10 * 1000);
  },
  computed: {
    ...mapGetters({
      allAssets: 'getAssets',
      isLoadingAssets: 'isLoadingAssets',
      currentOrganization: 'currentOrganization',
      getAssetTypes: 'getAssetTypes',
      hasAssetProjects: 'hasAssetProjects',
    }),
    getAssets() {
      if (this.organizationIds.length === 0) return this.allAssets;
      return this.allAssets.filter((a) => this.organizationIds.includes(a.organizationId));
    },
    chartOptionsPie() {
      const opt = { ...this.chartOptions };
      opt.legend.show = false;
      opt.dataLabels.formatter = (v, o) => o.w.globals.series[o.seriesIndex];
      opt.colors = [colors.green.darken1, colors.grey.lighten1];
      opt.plotOptions.pie.donut.labels.total.label = 'Totaal';
      opt.plotOptions.pie.donut.labels.total.showAlways = true;
      opt.plotOptions.pie.donut.labels.total.color = colors.grey.darken1;
      return opt;
    },
    chartOptionsPieOnline() {
      const opt = { ...this.chartOptionsPie };
      opt.plotOptions = JSON.parse(JSON.stringify(opt.plotOptions));
      opt.plotOptions.pie.donut.labels.total.formatter = (w) => {
        const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
        return `${w.globals.seriesTotals[0]} / ${total}`;
      };
      opt.labels = [this.$t('online'), this.$t('offline')];
      return opt;
    },
    chartOptionsPieFuel() {
      const opt = { ...this.chartOptionsPie };
      opt.plotOptions = JSON.parse(JSON.stringify(opt.plotOptions));
      opt.plotOptions.pie.donut.labels.total.formatter = (w) => {
        const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
        return `${w.globals.seriesTotals[0]} / ${total}`;
      };
      opt.labels = ['> 25%', '< 25%'];
      return opt;
    },
    chartOptionsPieAlarms() {
      const opt = { ...this.chartOptionsPie };
      opt.plotOptions = JSON.parse(JSON.stringify(opt.plotOptions));
      opt.plotOptions.pie.donut.labels.total.formatter = (w) => {
        const total = w.globals.seriesTotals.reduce((a, b) => a + b, 0);
        return `${total - w.globals.seriesTotals[0]} / ${total}`;
      };
      opt.colors = [colors.green.darken1, colors.orange.darken1, colors.red.darken1];
      opt.labels = [
        this.$t('assets.alarm.all-cleared'),
        this.$t('assets.alarm.warning.any'),
        this.$t('assets.alarm.error.any'),
      ];
      return opt;
    },
    kpis() {
      return [
        {
          name: this.$t('online-last-hour'),
          type: 'pie',
          calc: this.getAssets.filter((a) => a.isOnline).length,
          calcOf: this.getAssets.filter((a) => a.sensors.length).length,
          icon: 'mdi-wifi-sync',
          opts: this.chartOptionsPieOnline,
          show: this.getAssets.filter((a) => a.sensors.length).length > 0,
        },
        {
          name: `${this.$t('fuel-level')} > ${consts.FUEL_LOW}%`,
          type: 'pie',
          calc: this.getAssets
            .filter((a) => a.getSensorBySensorTypeKey('FUEL')?.lastMeasurement.value >= consts.FUEL_LOW).length,
          calcOf: this.getAssets.filter((a) => a.getSensorBySensorTypeKey('FUEL')?.lastMeasurement.value !== undefined).length,
          icon: 'mdi-fuel',
          opts: this.chartOptionsPieFuel,
          show: this.getAssets.filter((a) => a.getSensorBySensorTypeKey('FUEL')?.lastMeasurement.value !== undefined).length > 0,
        },
        {
          name: `${this.$t('battery-level')} > ${consts.FUEL_LOW}%`,
          type: 'pie',
          calc: this.getAssets
            .filter((a) => a.getSensorBySensorTypeKey('VTRON_SOC')?.lastMeasurement.value >= consts.FUEL_LOW).length,
          calcOf: this.getAssets
            .filter((a) => a.getSensorBySensorTypeKey('VTRON_SOC')?.lastMeasurement.value !== undefined).length,
          icon: 'mdi-battery-20',
          opts: this.chartOptionsPieFuel,
          show: this.getAssets.filter((a) => a.getSensorBySensorTypeKey('FUEL')?.lastMeasurement.value !== undefined).length > 0,
        },
        {
          name: this.$t('alarms-or-malfunction'),
          type: 'pie-multiple',
          series: [
            this.getAssets.filter((a) => !a.hasAnyActiveAlarms && !a.malfunction).length,
            this.getAssets.filter((a) => a.hasActiveAlarmWarnings
              && !a.malfunction
              && !a.hasActiveAlarmErrors).length,
            this.getAssets.filter((a) => a.malfunction || a.hasActiveAlarmErrors).length,
          ],
          calcOf: this.getAssets.length,
          icon: 'mdi-bell-ring-outline',
          opts: this.chartOptionsPieAlarms,
          show: true,
        },
        {
          name: this.$t('current-power'),
          type: 'sum',
          calc: this.getAssets
            .filter((a) => a.getSensorBySensorTypeKey('PGEN')?.lastMeasurement.value)
            .map((a) => a.getSensorBySensorTypeKey('PGEN')?.lastMeasurement.value)
            .reduce((a, b) => a + b, 0),
          sensorType: this.getAssets.find((a) => a.getSensorBySensorTypeKey('PGEN')?.sensorType)?.getSensorBySensorTypeKey('PGEN')?.sensorType,
          icon: 'mdi-lightning-bolt',
          show: this.getAssets.filter((a) => a.getSensorBySensorTypeKey('PGEN')?.lastMeasurement.value !== undefined).length > 0,
        },
        {
          name: this.$tc('asset-types.type', 2),
          type: 'asset-types',
          icon: 'mdi-router-wireless',
          items: this.getAssetTypes.map((at) => ({
            ...at,
            count: this.getAssets.filter((a) => a.assetTypeId === at.id).length,
          })),
          show: true,
        },
        {
          name: this.$t('weather'),
          type: 'weather',
          icon: 'mdi-cloud',
          show: true,
        },
        // {
        //   name: 'Tijd',
        //   type: 'clock',
        //   icon: 'mdi-clock-outline',
        // },
        // {
        //   name: 'Onderweg',
        //   calc: 2, // this.assets.filter((a) => a.vehicle).length,
        //   calcOf: 2, // this.assets.filter((a) => a.vehicle).length,
        //   icon: 'mdi-truck-fast-outline',
        // },
      ].filter((k) => k.show);
    },
    actualWeather() {
      if (!this.weatherData) return false;
      return this.weatherData.actual.stationmeasurements.find((a) => a.$id === '31');
    },
    actualWeatherIcon() {
      if (!this.weatherData) return false;
      return this.actualWeather.iconurl.replace(/30/g, 300);
    },
    filteredAssetsWithLatLng() {
      return this.getAssets.filter((a) => a.getLatLng());
    },
  },
  methods: {
    loadWeatherData() {
      this.loadingWeather = true;
      axios
        .get('https://data.buienradar.nl/2.0/feed/json')
        .then((response) => {
          this.loadingWeather = false;
          this.weatherData = response.data;
        });
    },
  },
  beforeRouteLeave(from, to, next) {
    if (this.intervalData) {
      clearInterval(this.intervalData);
    }
    if (this.intervalTime) {
      clearInterval(this.intervalTime);
    }
    next();
  },
});
</script>

<style>
body {
  -ms-overflow-style: none;
  scrollbar-width: none;
}
body::-webkit-scrollbar {
  display: none;
}
</style>
